.App {
  text-align: center;
  user-select: none;
}
#logo-login{
  margin-top: 50px;
  width: 20%;
}
.Seperator {
  width: 100%;
  height: 2px;
  background-color: rgb(209, 231, 221);
}
.dimm{
  width: 100%;
  height: 30px;
  border-radius: 5px;
  background-color: #ccc;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.dimm::-webkit-slider-thumb {
  width: 45px;
  height: 35px;
  border-radius: 15%;
  background-color: rgb(25, 135, 84);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.dimm::-moz-range-thumb {
  width: 45px;
  height: 35px;
  border-radius: 15%;
  background-color: rgb(25, 135, 84);
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.transform-component-module_wrapper__SPB86{
  float: left;
  width: 80% !important;
  margin-left: 10% !important;
  height: 97.5vh !important;
  border-left-style: solid;
  border-left-width: 2px;
  border-left-color: darkgray;
}

@keyframes colorChange {
  0% {
    fill: red;
  }
  50% {
    fill: black;
  }
  100% {
    fill: red;
  }
}

.element {
  box-shadow: inset 0px 0px 18px 5px #000000;
  animation: colorChange 1s infinite;
}

.hoverT{
  opacity: 0.5;
}
.hoverT:hover{
  opacity: 1;
}